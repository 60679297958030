import { defineStore } from 'pinia'

export const dashboardStore = defineStore('dashboard', {
  state: () => ({
    user: null,
    token: '',
    // eslint-disable-next-line camelcase
    client_id: '',
    activeOrders: [],
    orders: [],
    activePayoutMethods: [],
    activePaymentMethods: [],
    spbBanks: [],
    history: [],
    documents: [],
    orderDocuments: [],
    photos: [],
    order: undefined,
    formFields: null,
    lkCards: [],
    banner: null,
    prolongationDocuments: [],
    contractDocuments: [],
    closeLoanDocuments: [],
    signDocuments: [], //todo: использутеся вообще или я потерял?
    admin: false,
    moratorium: {
      status: false,
      // eslint-disable-next-line camelcase
      end_date: null
    },
    defaultTariff: [],
    calculatorData: undefined,
    personalData: {
      firstName: '',
      lastName: '',
      middleName: '',
      email: '',
      dateOfBirth: '',
      sex: 0,
    },
    showMenu: false
  })
})
