import { signupStore } from '@/store/signup'
import axios from 'axios'
import apiRequest from '@/middlewares/api-request'
import { reject } from 'eslint-plugin-promise/rules/lib/promise-statics'
import { toServerPhone } from '@/utils/converters'

export class SignupLayer{

  constructor() {
    this.signupStore = signupStore()
  }

  install(app){
    app.provide('SignupLayer', this)
  }

  setDashboardLayer(dashboardLayer){
    this.dashboardLayer = dashboardLayer
  }

  setNotificationLayer(notificationLayer){
    this.notificationLayer = notificationLayer
  }

  setCsrfToken(res){
    this.signupStore.$patch({
      csrf: res
    })
  }

  getToken(){
    return this.dashboardLayer?.getToken?.() || ''
  }

  setClientId(res){
    this.signupStore.$patch({
      // eslint-disable-next-line camelcase
      user_id: res
    })
  }

  setCard({ data }){
    this.signupStore.$patch((state) => {
      state.cards.push(data.card)
      state.cards = [...new Set(state.cards.map(JSON.stringify))].map(
        JSON.parse //зачем так сложно?
      )
    })
  }

  setStep({ data }){
    this.signupStore.$patch({
      step: data.data['step_data'] ? data.data['step_data']['step_value'] : null
    })
  }

  setPhone(phone, isNewClient = true, isEmailExist = false){
    this.signupStore.$patch({
      phone: phone,
      isNewClient: isNewClient,
      isEmailExist: isEmailExist
    })
  }

  getPhone() {
    return this.signupStore.$state.phone
  }

  setFileTypes({ data }){
    this.signupStore.$patch({
      availableFileTypes: data.data['required_fields']
    })
  }

  createUser(payload){
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve) => {
      return axios.post(
        `${process.env.VUE_APP_API_URL}api/clients/create`,
        payload
      ).then((res) => {
        if (res?.data?.code === 200) {
          this.setClientId(res)
        }
        resolve(res)
      }).catch((error) => {
        resolve(error.response)
      })
    })
  }

  resetByEmail(phone){
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve) => {
      return axios.post(
        `${process.env.VUE_APP_API_URL}api/clients/reset_by_email_url`,
        { phone: toServerPhone(phone) }
      ).then((res) => {
        resolve(res)
      }).catch((error) => {
        resolve(error.response)
      })
    })
  }

  confirmByEmail(token, password, password_confirmation){
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve) => {
      return axios.post(
        `${process.env.VUE_APP_API_URL}api/clients/confirm_reset`,
        {
          token: token,
          password: password,
          password_confirmation: password_confirmation
        }
      ).then((res) => {
        resolve(res)
      }).catch((error) => {
        resolve(error.response)
      })
    })
  }

  async updateUser(fields){
    return await apiRequest.function(
      'PATCH',
      `api/clients/update?step=${this.signupStore.$state.step}`,
      [this.setStep.bind(this)],
      null,
      fields,
      { Authorization: `Bearer ${this.getToken()}` }
    )
  }

  async finalUpdateUser(payload){
    return await apiRequest.function(
      'PATCH',
      'api/clients/update',
      [this.setStep.bind(this)],
      null,
      payload,
      { Authorization: `Bearer ${this.getToken()}` }
    )
  }

  sendPass({ fields }){
    return new Promise((resolve) => {
      const res = axios.patch(
        `${process.env.VUE_APP_API_URL}api/clients/passport_data`,
        fields,
        {
          headers: {
            Authorization: 'Bearer ' + this.getToken()
          }
        }
      ).catch((error) => {
        if (error.response.status == 422) {
          this.notificationLayer?.addNotification?.({
            message: error.response.data.message
          })
        }
        resolve(error.response)
      })

      resolve(res)
    })
  }

  sendAddress({ fields }){
    return new Promise((resolve) => {
      axios
        .post(`${process.env.VUE_APP_API_URL}api/clients/address`, fields, {
          headers: {
            Authorization: 'Bearer ' + this.getToken()
          }
        })
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          resolve(error.response)
        })
    })
  }

  async uploadPhoto ({ files }){
    return await apiRequest.function(
      'POST',
      'api/files/add',
      null,
      null,
      files,
      {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${this.getToken()}`
      }
    )
  }

  async uploadPhotoAuth({ fields }){
    return await apiRequest.function(
      'POST',
      'api/files/append/auth',
      null,
      null,
      fields,
      {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${this.getToken()}`
      }
    )
  }

  async deletePhoto(id){
    if(id && id!=''){
      return await apiRequest.function(
        'DELETE',
        `api/files/delete/${id}/auth`,
        null,
        null,
        null,
        {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${this.getToken()}`
        }
      )
    }

    return true
  }

  async replacePhoto({ id, fields }){
    return await apiRequest.function(
      'POST',
      `api/files/update/${id}/auth`,
      null,
      null,
      fields,
      {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${this.getToken()}`
      }
    )
  }

  async sendJobData({ fields }){
    return await apiRequest.function(
      'POST',
      'api/clients/createJobData',
      null,
      null,
      fields,
      { Authorization: `Bearer ${this.getToken()}` }
    )
  }

  async getBindCardLink(referer){
    return await apiRequest.function(
      'GET',
      `api/pay/link?referer=${referer}`,
      null,
      null,
      null,
      { Authorization: `Bearer ${this.getToken()}` }
    )
  }

  async setClientServices ({ insurance }) {
    return await apiRequest.function(
      'POST',
      'api/clients/services',
      null,
      null,
      // eslint-disable-next-line camelcase
      { dok: false, rejection_reason: false, insurance },
      { Authorization: `Bearer ${this.getToken()}` }
    )
  }

  async getBindCardCallback ({ search }) {
    const res = await apiRequest.function(
      'POST',
      `api/pay/callback/best2pay${search}`,
      null,
      null,
      null,
      { Authorization: `Bearer ${this.getToken()}` }
    )

    if (res === undefined) return
    const { code } = res.data

    if (code === 200) {
      this.setCard(res.data)
    }

    return res
  }

  sendBid({ fields, url = '' }){
    return new Promise((resolve) => {
      axios
        .post(`${process.env.VUE_APP_API_URL}api/orders/store${url}`, fields, {
          headers: {
            Authorization: 'Bearer ' + this.getToken()
          }
        })
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          resolve(error)
        })
    })
  }

  async getStep(){
    const res = await apiRequest.function(
      'GET',
      'api/clients/step',
      [this.setStep.bind(this)],
      null,
      null,
      { Authorization: `Bearer ${this.getToken()}` }
    )

    if (res?.status === 404 || res?.data?.code === 404) {
      window.location.href = '/'
    }

    return res
  }

  async bidVerify(payload){
    return await apiRequest.function(
      'POST',
      'api/orders/verify',
      null,
      null,
      payload,
      { Authorization: `Bearer ${this.getToken()}` }
    )
  }

  async getSettings(){
    if(this.signupStore.$state.settings) {
      return this.signupStore.$state.settings
    }

    const res = await apiRequest.function(
      'GET',
      'api/registration/settings',
      [],
      {},
      null,
    )

    if (res?.status === 404 || res?.data?.code === 404) {
      window.location.href = '/'
    }
    if(res?.data?.data){
      this.signupStore.$patch({
        settings: res.data.data
      })

      return res.data.data
    }

    return undefined
  }

  async sendFile({ type, file }) {
    const formData = new FormData()

    formData.append('fileable_type', 'App\\Modules\\Client\\Models\\Client')
    formData.append('is_real_photo', Number(false))
    formData.append(`files[${type}]`, file)
    const { status, data } = await apiRequest.function(
      'POST',
      'api/files/validate',
      [],
      {},
      formData,
      {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${ this.getToken() }`
      }
    )

    if (data?.recognize) {
      await this.dashboardLayer.getUserInfo()
      await this.dashboardLayer.notify({
        message: 'Данные успешно распознаны',
        type: 'success'
      })
    }

    if (status === 200) {
      this.signupStore.$patch((state) => {
        state.files[type] = URL.createObjectURL(new Blob([file], { type: file.type }))
      })
    }

    if (status === 422) {
      this.dashboardLayer.notify({
        message: 'не удалось загрузить файл, попробуйте снова'
      })
    }

    if (status === 500) {
      this.dashboardLayer.notify({
        message: 'что-то пошло не так'
      })
    }
  }

  async loadFiles(files) {
    for (const file of files) {
      await this.getFileFromServer(file.id).then((res) => {
        let fileServer = res.data

        this.signupStore.$patch((state) => {
          state.files[file.type] = URL.createObjectURL(fileServer, { type: fileServer.type })
        })
      })
    }
  }

  async getFileFromServer(fileId) {
    return await axios.get(`${process.env.VUE_APP_API_URL}api/files/get/${fileId}`, {
      headers: {
        'Authorization': `Bearer ${ this.getToken() }`,
        'content-type': 'image/png',
        'accept': 'image/png'
      },
      responseType: 'blob'
    })
  }

  async getUserCode(payload) {
    return await apiRequest.function(
      'POST',
      'api/sms/send',
      null,
      null,
      payload,
      { Authorization: `Bearer ${ this.getToken() }` }
    )
  }

  getFile(type) {
    if (this.signupStore.$state.files[type] !== undefined) {
      return this.signupStore.$state.files[type]
    }

    return null
  }
}
