export const DisableBodyScroll = () => {
  if (typeof window.scrollPosition === 'undefined') {
  // Сохраняем текущую позицию прокрутки только при первом открытии модального окна
    window.scrollPosition = window.scrollY || window.pageYOffset

    // Добавляем класс для блокировки прокрутки
    document.body.style.overflow = 'hidden'
    document.body.style.position = 'fixed'
    document.body.style.top = `-${window.scrollPosition}px`
  }
}

export const EnableBodyScroll = () => {
  // Для закрытия модального окна, восстанавливаем прокрутку только если позиция была сохранена
  if (typeof window.scrollPosition !== 'undefined') {
    document.body.style.overflow = ''
    document.body.style.position = ''
    document.body.style.top = ''
    window.scrollTo(0, window.scrollPosition)

    // Сбрасываем сохраненную позицию прокрутки
    window.scrollPosition = undefined
  }
}
