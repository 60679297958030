import axios from 'axios'

export default {
  async function (method, url, mutations, params, payload, headers = {}) {
    return await axios({
      method,
      params,
      headers,
      data: payload,
      url: process.env.VUE_APP_API_URL + url
    })
      .then((res) => {
        if (mutations?.length) {
          for (const func of mutations) {
            if(typeof func === 'function'){
              func(res)
            }
          }

          return res
        }

        return res
      })
      .catch((error) => {
        return error?.response
        // if (status == 401) {
        //   store.dispatch("LOGOUT");
        //   store.state.global_notifications.notification =
        //     "Токен истёк или не валиден, пожалуйста пройдите авторизацию";
        //   store.commit("SET_GLOBAL_NOTIFICATION");
        //   return;
        // }
      })
  }
}
