export const ORDER_STATUS_IDS = {
  NEW: 0,
  ACCEPTED: 1,
  APPROVED: 2,
  REFUSED: 3,
  GIVEN: 4,
  FAIL_TO_GIVE: 5,
  REPAID: 6,
  REJECTED_BY_CLIENT: 7,
  EXPIRED: 8,
  SIGNED: 9,
  DRAFT: 10,
  INACTIVE: 11,
  PRE_APPROVED: 12,
}

export const STATUSES = {
  CONSIDERATION: 'Рассмотрение',
  ISSUED: 'Выдан',
  FAILED: 'Отказано',
  ACCEPTED: 'Принята',
  EXPIRED: 'Просрочен',
  REPAID: 'Погашен',
}

export const STATUSES_COLOR_MAP = {
  [STATUSES.CONSIDERATION]: '#6BD390',
  [STATUSES.ISSUED]: '#19A873',
  [STATUSES.FAILED]: '#F01524',
  [STATUSES.ACCEPTED]: '#6BD390',
  [STATUSES.EXPIRED]: '#F01524',
  [STATUSES.REPAID]: '#CD2759',
}
